import React, { useCallback, useEffect, useState } from 'react'
import { getCustomerInfo, updateCustomerIban } from '../../actions/customerActions'
import Fade from '@material-ui/core/Fade';

const CustomerIban = ({ match }) => {
    const { params: { bank_id, id } } = match;

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [withDraw, setWithDraw] = useState(null);

    const handleCustomer = useCallback(async (params) => {
        const response = await getCustomerInfo(params);

        setData(response?.bank_account?.ibans || []);
        setWithDraw(response?.bank_account?.withdrawal_iban)
        setLoading(false)
    }, []);

    useEffect(() => {
        handleCustomer(match?.params)

        return () => setLoading(false);
    }, []);

    const handleDelete = useCallback((e) => {
        e.preventDefault();

        const currentIban = e.currentTarget.getAttribute('data-iban');
        const currentId = e.currentTarget.getAttribute('data-id');

        if (currentIban) {
            setData((data) => data?.filter(item => item.number !== currentIban))
        } else {
            setData((data) => data?.filter(item => +item.id !== +currentId))
        }

        const exists = data.some(item => item.number === withDraw);

        if(!exists.length > 0) {
            setWithDraw(null);
        } 
    }, [JSON.stringify(data)]);

    console.log(data, withDraw)


    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
       

        setLoading(true)
       

        const cleanedData = data?.map(item => {
            const { new: _, id: __, ...rest } = item;
            return rest;
        });



        await updateCustomerIban(id, {
            "bank_account": {
                "ibans": cleanedData,
                "withdrawal_iban": withDraw
            }
        }, bank_id);

        await  handleCustomer(match?.params)

        await setLoading(false)

    }, [JSON.stringify(data), withDraw]);


    const handleInput = ({ target }) => {
        const { name, value } = target;
        const currentAttr = target.getAttribute('data-attr')
        const currentId = target.getAttribute('data-id')


        if (currentAttr) {
            if (name === 'number') {
                const findNumber = data?.map(item => {
                    if (item.number === currentAttr) {
                        return {
                            ...item,
                            number: value
                        }
                    } else return item
                })

                setData(findNumber);
            } else {
                const findNumber = data?.map(item => {
                    if (item.number === currentAttr) {
                        return {
                            ...item,
                            title: value
                        }
                    } else return item
                })

                setData(findNumber);
            }

        } else if (currentId) {
            if (name === 'number') {
                const findNumber = data?.map(item => {
                    if (+item.id === +currentId) {
                        return {
                            ...item,
                            number: value
                        }
                    } else return item
                })

                setData(findNumber);
            } else {
                const findNumber = data?.map(item => {
                    if (+item.id === +currentId) {
                        return {
                            ...item,
                            title: value
                        }
                    } else return item
                })

                setData(findNumber);
            }
        }
    }


    const handleChangeWithdrawal = useCallback((iban, elem) => {
        setWithDraw(elem ? iban : null);
    }, [withDraw]);

    console.log(data);

    return (
        <div className='row'>
            <div className='col-12 col-lg-12'>
                <div className='card'>
                    <div className="card-header">
                        <i className="fa fa-align-justify"></i> Customer details
                    </div>
                    <form className='card-body' >
                        <div className='row'>
                            <div className="col-12">
                                <fieldset className="p-2 pb-5 pl-5 pr-5">
                                    <legend className="d-inline">IBANs</legend>
                                    <div className="row">
                                        <div className="col-12">

                                            <>
                                                {
                                                    data?.length > 0 ?
                                                        data?.map((item, index) =>
                                                            <div key={JSON.stringify(item)} className='d-flex align-items-center mb-2'>
                                                                <input className="form-control mr-2" type='text' placeholder="Enter the ibans number" name="number" defaultValue={item?.number} data-attr={item?.number} data-id={item?.id} onChange={handleInput} />

                                                                <select className="form-control" name="title" defaultValue={item?.title} data-attr={item?.number} data-id={item?.id} onChange={handleInput}>
                                                                    <option selected disabled>Select an option</option>
                                                                    <option value={'Kreditkarte'}>
                                                                        Kreditkarte
                                                                    </option>
                                                                    <option value={'Girokonto'}>
                                                                        Girokonto
                                                                    </option>
                                                                </select>

                                                                <button className='border-0 ml-2 p-2' data-iban={item?.number} data-new={item?.new} data-id={item?.id} onClick={handleDelete}>
                                                                    <i className="icon-trash" />
                                                                </button>

                                                                <input type='radio' name="drone" checked={item.number === withDraw} onChange={(e) => handleChangeWithdrawal(item?.number, e.target.checked)}></input>
                                                            </div>
                                                        )
                                                        : <p className='d-flex align-items-center mb-2'>There are no IBANs</p>
                                                }

                                                <button type="button" className={"btn btn-primary mt-3"} onClick={() => setData(el => ([...el, { new: true, id: el?.length }]))}>Add IBAN</button>

                                            </>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>




                            <div className="col-12"
                            >
                                <div className='ibans-wrapper-button mt-5' style={{width: 'fit-content'}}>
                                    <button type="submit" className={'btn btn-primary'} onClick={handleSubmit}>
                                        Submit
                                    </button>
                                </div>

                            </div>

                        </div>
                    </form>


                    <Fade in={loading} mountOnEnter unmountOnExit>
                        <span style={{
                            position: 'fixed',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            marginLeft: 150,
                            color: 'black',
                            background: 'white',
                            zIndex: 100,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 40,
                            textAlign: 'center'
                        }}>Loading ...</span>
                    </Fade>
                </div>
            </div>
        </div>



    )
}

export default CustomerIban;